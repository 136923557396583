import { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import { BsImage } from "react-icons/bs";

const RowsStocktakeProducts = ({ product, disabledFields, onProduct, onAccept, onDelete, onCompatible }) => {
  const [productData, setProductData] = useState({
    actual: "",
    discrepancy: "",
    notes: "",
    check: false
  });

  const handleDown = () => {
    onAccept(product, !productData.check);
  };

  const handleChange = (evt) => {
    if (evt?.target?.name === "actual" && !isNaN(evt.target.value)) {
      onProduct(evt, product);
      setProductData({...productData,
        actual: evt.target.value,
        discrepancy: Math.abs(Number(product.expected) - Number(evt.target.value))
      });
    } else if (evt?.target?.name === "notes") {
      onProduct(evt, product);
      setProductData({...productData, [evt.target.name]: evt.target.value});
    }
  };

  useEffect(() => {
    setProductData({...productData,
      actual: product?.actual,
      discrepancy: product?.discrepancy,
      notes: product?.notes,
      check: product?.check
    });
  }, [product, product?.check]);

  return (
    <tr>
      <td className="w-[20%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">
        <div className="w-full flex justify-center items-center space-x-2">
          <div className="w-[20%]">
            { product.data.productInventory.product?.productImages[0] ?
              <div className="w-[45px] h-[45px] flex justify-center items-center border border-ag-secondary-light rounded-lg p-1">
                <img className="w-full h-full object-contain" src={product.data.productInventory.product.productImages[0]} />
              </div>
              :
              <span className="text-4xl"><BsImage /></span>
            }
          </div>
          <div className="w-[80%] text-left text-[10px]">
            <div className="space-y-[-3px]">
              <div>
                <span>{ product.data.productInventory.product.nags }</span>
              </div>
              <div>
                {
                  product?.data?.productInventory?.product?.compatible_vehicles.map((prodComp, i) => {
                    if (i <= 2) {
                      return (
                        <p className="truncate w-36" key={i} title={
                          `${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year} ` +
                                                    `${prodComp?.brand} ` +
                                                    `${prodComp?.model} ` +
                                                    `${prodComp?.type_car}`
                        }>
                          { String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year }{' '}
                          { prodComp?.brand }{' '}
                          { prodComp?.model }{' '}
                          { prodComp?.type_car }
                        </p>
                      );
                    }
                  })
                }
                {
                  product?.data?.productInventory?.product?.compatible_vehicles.length > 3 ?
                    <p className="truncate w-36 font-semibold cursor-pointer" onClick={() => onCompatible(product?.data?.productInventory?.product)}>
                      See compatible vehicles +
                    </p>
                    : null
                }
              </div>
            </div>
            {/* <div className="space-y-[-3px]">
                            <div className="flex justify-start items-start space-x-1">
                                <span>Vendors:</span>
                                <span>
                                    {
                                        product.data.productInventory.product.vendors.map((vendor, i) => {
                                            if ((i+1) === product.data.productInventory.product.vendors.length) {
                                                return `${vendor.name}`;
                                            } else {
                                                return `${vendor.name}, `;
                                            }
                                        })
                                    }
                                </span>
                            </div>
                        </div> */}
          </div>
        </div>
      </td>
      <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">
        { product.data.rack ? product.data.rack.name : product.rack.name }
      </td>
      <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">
        { product.data.quantity ? product.data.quantity : product.expected }
      </td>
      <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">
        <input
          className={`w-full h-10 text-center rounded-xl disabled:bg-zinc-200 ${ productData.check ? "border-2 border-green-500" : "border border-ag-secondary-light" }`}
          name="actual"
          id={product._id}
          type="text"
          disabled={disabledFields}
          onChange={handleChange}
          value={productData.actual}
          // defaultValue={product.actual ? product.actual : ""}
        />
      </td>
      <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">
        <input
          className={`w-full h-10 text-center rounded-xl disabled:bg-zinc-200 ${ productData.check ? "border-2 border-green-500" : "border border-ag-secondary-light" }`}
          name="discrepancy"
          type="text"
          disabled={true}
          onChange={handleChange}
          value={Math.abs(productData.discrepancy)}
          // defaultValue={product.discrepancy ? product.discrepancy : ""}
          // value={product.discrepancy}
        />
      </td>
      <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">
        <input
          className={`w-full h-10 text-center rounded-xl disabled:bg-zinc-200 ${ productData.check ? "border-2 border-green-500" : "border border-ag-secondary-light" }`}
          name="notes"
          type="text"
          disabled={disabledFields}
          onChange={handleChange}
          value={productData.notes}
          // defaultValue={product.notes ? product.notes : ""}
        />
      </td>
      <td className="w-[5%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">
        <div className="flex justify-center items-center space-x-1">
          {
            productData.check ?
              <button className="disabled:bg-zinc-200" type="button" disabled={disabledFields} onClick={() => handleDown()}>
                <span className="text-red-600 text-3xl">
                  <BiXCircle />
                </span>
              </button>
              :
              <button className="disabled:bg-zinc-200" type="button" disabled={disabledFields} onClick={() => handleDown()}>
                <span className="text-green-600 text-3xl">
                  <BiCheckCircle />
                </span>
              </button>
          }
          <button className="disabled:bg-zinc-200" type="button" disabled={disabledFields} onClick={() => onDelete(product)}>
            <span className="text-red-600 text-3xl">
              <AiOutlineDelete />
            </span>
          </button>
        </div>
      </td>
    </tr>
  );
};

export default RowsStocktakeProducts;