import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from 'react-select';

const INITIAL_OPTIONS = {
  warehouse: [],
  names: "",
  role: ""
};

const Filters = ({ enableFilters, enableAdd, enableRoles, warehouseOptions, namesOptions, roleOptions, onFilters, onAdd, clearFilters }) => {
  const [focusClass, setFocusClass] = useState({
    search: { placeholder: "Search", status: false },
    warehouse: { placeholder: "Warehouse", status: false },
    names: { placeholder: "Nags", status: false },
    role: { placeholder: "Type", status: false }
  });

  const [options, setOptions] = useState(INITIAL_OPTIONS);
  const [search, setSearch] = useState('');

  const handleChange = (evt, name) => {
    if (name === 'warehouse') {
      setSearch('');
      setOptions({ ...options, [name]: evt });
    } else {
      if (evt.currentTarget.name === "search") {
        setSearch(evt.target.value);
        onFilters(evt.currentTarget.value, "filters");
      } else {
        setSearch('');
        if (evt.currentTarget.value === "default") {
          setOptions({...options, [evt.currentTarget.name]: ""});
        } else {
          setOptions({...options, [evt.currentTarget.name]: evt.currentTarget.value});
        }
      }
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setSearch('');
    console.log('se mandan al filtro: ',options);
    onFilters(options, "options");
  };

  useEffect(() => {
    setOptions(INITIAL_OPTIONS);
    setSearch('');
  }, [clearFilters]);

  return (
    <>
      <div>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-3 xl:gap-6 md:grid-cols-4">
            <div className="md:col-span-3 space-y-3">
              <div>
                <input
                  className={`w-full text-ag-secondary-letter text-sm lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.search.status ? "text-left" : "text-center"}`}
                  type="text"
                  name="search"
                  placeholder={focusClass.search.placeholder}
                  value={search}
                  onChange={handleChange}
                  disabled={!enableFilters}
                  onFocus={() => setFocusClass({...focusClass, search: { placeholder: "", status: true }})}
                  onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, search: { placeholder: "Search", status: false }}) : null}
                  maxLength={60}
                />
              </div>

              <div className="grid gap-3 xl:gap-6 md:grid-cols-4">
                <div>
                  <Select
                    classNames={{
                      control: () => 'reactSelectDefault'
                    }}
                    className="text-center text-xs xl:text-base"
                    onChange={(e) => handleChange(e, 'warehouse')}
                    options={warehouseOptions.map(option => ({ value: option._id, label: option.name }))}
                    placeholder={"All Warehouses"}
                    value={options.warehouse}
                    isMulti
                  />
                </div>

                <div>
                  <select
                    className={`w-full text-ag-secondary-letter text-sm lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.names.status ? "text-left" : "text-center"}`}
                    name="names"
                    defaultValue="default"
                    onChange={handleChange}
                    disabled={!enableFilters}
                    onFocus={() => setFocusClass({...focusClass, names: { placeholder: "", status: true }})}
                    onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, names: { placeholder: "Names", status: false }}) : null}
                  >
                    <option value="default">Name</option>
                    {
                      namesOptions.map((element, index) => {
                        return <option key={index} value={element.label}>{element.label}</option>;
                      })
                    }
                  </select>
                </div>

                <div>
                  <select
                    className={`w-full text-ag-secondary-letter text-sm lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.role.status ? "text-left" : "text-center"}`}
                    name="role"
                    defaultValue="default"
                    onChange={handleChange}
                    disabled={!enableFilters}
                    onFocus={() => setFocusClass({...focusClass, role: { placeholder: "", status: true }})}
                    onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, role: { placeholder: "Role", status: false }}) : null}
                  >
                    <option value="default">Role</option>
                    {
                      roleOptions.map((element, index) => {
                        return <option key={index} value={element.label}>{element.label}</option>;
                      })
                    }
                  </select>
                </div>

                <div>
                  <button
                    className="w-full text-center text-ag-secondary-letter text-sm lg:text-base border border-gray-500 p-[7.5px] rounded-xl disabled:bg-zinc-200"
                    type="submit"
                    disabled={!enableFilters}
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>

            <div className="space-y-3">
              <div>
                <button
                  className="w-full bg-ag-primary-light text-white text-sm lg:text-base p-2 rounded-xl disabled:bg-zinc-200"
                  type="button"
                  onClick={() => { onAdd(true); }}
                  disabled={!enableAdd}
                >
                  New record
                </button>
              </div>

              <div>
                <Link to={!enableRoles ? "" : "/administrators/roles"}>
                  <button
                    className="w-full bg-ag-primary-light text-white text-sm lg:text-base p-2 rounded-xl disabled:bg-zinc-200"
                    type="button"
                    disabled={!enableRoles}
                  >
                    Roles
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Filters;