import { useContext, useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getWarehouses } from "../../../../../services/Warehouse";
import { getInventoryTransferId, saveInventoryTransfer, updateInventoryTransferId } from "../../../../../services/Transfer";
import { formatReverseDate, removeAccents, alertOptions, formatDate, compareDates } from "../../../../../utils/Utilities";
import { PermitsContext } from "../../../../../services/Permits";
import { validateInventoryTransfer } from "../../../../../utils/Validators";
import { toast } from "react-toastify";
import { getInventoryAddWithRacks } from "../../../../../services/Inventory";
import Filters from "./Filters";
import FormTransfer from "./FormTransfer";
import TableSentProducts from "./TableSentProducts";
import ProductsAvailable from "../../ProductsAvailable";
import TableHistoryTransfer from "../TableHistoryTransfer";
import Modal from "../../../../../components/modal/Modal";
import Loader from "../../../../../components/loader/Loader";
import ModalCompatible from "../../../products/ModalCompatible";

const SaveTransfer = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loader, setLoader] = useState(false);
  const { dataSession } = useContext(PermitsContext);

  const [showModal, setShowModal] = useState({ data: "", status: false });
  const [showConfirmModal, setShowConfirmModal] = useState({ status: false, title: "", element: null });
  const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
  const [warehouses, setWarehouses] = useState([]);
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [warehouseFromOptions, setWarehouseFromOptions] = useState([]);
  const [statusOptions] = useState([
    { label: "Draft", value: "draft" },
    { label: "In Process", value: "inprocess" },
  ]);
  const [productsTransfer, setProductsTransfer] = useState([]);
  const [productsTransferUniv, setProductsTransferUniv] = useState([]);
  const [transfer, setTransfer] = useState({
    _id: "",
    status: "",
    date: "",
    expectedBy: "",
    fromWarehouse: "",
    fees: "",
    toWarehouse: "",
    freight: "",
    productsInventory: [],
    totalQuantity: "",
    subTotal: "",
    total: "",
    message: "",
    cratedBy: "",
    movements: []
  });

  const handleFilters = (evt) => {
    if (evt.target.value === "") {
      setProductsTransfer(productsTransferUniv);
    } else {
      let palabra = new RegExp(`${removeAccents(evt.target.value)}.*`, "i");
      const productsFound = productsTransferUniv.filter(element => (
        palabra.test(removeAccents(element.data.productInventory.product?.nags)) ||
                palabra.test(removeAccents(element.data.productInventory.product?.name)) ||
                palabra.test(removeAccents(element.data.productInventory.product?.model)) ||
                palabra.test(removeAccents(element.data.productInventory.product?.brand)) ||
                palabra.test(removeAccents(element.data.productInventory.product?.year)) ||
                palabra.test(removeAccents(element.data.productInventory.product?.glassType)) ||
                palabra.test(removeAccents(element.data.productInventory.product?.type_car)) ||
                palabra.test(removeAccents(element.data.productInventory.product?.description)) ||
                palabra.test(element.data.productInventory.product?.price) ||
                palabra.test(removeAccents(element.data.productInventory.product?.barcode1)) ||
                palabra.test(removeAccents(element.data.productInventory.product?.barcode2)) ||
                palabra.test(element.data.productInventory.product?.totalQuantity) ||
                palabra.test(removeAccents(element?.note)) ||
                palabra.test(element?.quantity) ||
                palabra.test(element?.totalCost)
      ));
      setProductsTransfer(productsFound);
    }
  };

  const onResponseProducts = (respProducts) => {
    setShowModal({...showModal, data: "", status: false});

    let acumQuantity = 0;
    let acumTotal = 0;
    const newProducts = respProducts.map((productElement) => {
      const foundProduct = productsTransfer.find(element => (`${element.data.productInventory._id}${element.data.rack._id}` === `${productElement.productInventory._id}${productElement.rack._id}`));

      acumQuantity += foundProduct ? foundProduct?.quantity : 1;
      acumTotal += foundProduct ? (Number(foundProduct?.quantity) * Number(productElement.productInventory.cost)) : Number(productElement.productInventory.cost);

      return {
        _id: productElement.productInventory._id,
        rack: productElement.rack._id,
        quantity: foundProduct ? foundProduct?.quantity : 1,
        defaultCost: productElement.productInventory.cost,
        totalCost: foundProduct ? (Number(foundProduct?.quantity) * Number(productElement.productInventory.cost)) : productElement?.productInventory.cost,
        data: productElement
      };
    });

    setProductsTransfer(newProducts);
    setProductsTransferUniv(newProducts);
    setTransfer({...transfer,
      totalQuantity: acumQuantity,
      subTotal: acumTotal,
      total: acumTotal
    });
  };

  const handleProduct = (evt, productResp) => {
    let acumQuantity = 0;
    let acumSubtotal = 0;
    let acumTotal = 0;
    let newProducts = productsTransfer.map(element => {
      acumSubtotal += Number(element.defaultCost);
      if (`${productResp._id}${productResp.rack}` === `${element._id}${element.rack}`) {
        acumQuantity += Number(evt.target.value);
        acumTotal += (Number(evt.target.value) * Number(element.defaultCost));
        return {
          _id: element.data.productInventory._id,
          rack: element.rack,
          quantity: Number(evt.target.value),
          defaultCost: element.defaultCost,
          totalCost: Number(evt.target.value) * Number(element.defaultCost),
          data: element.data
        };
      }
      acumQuantity += Number(element.quantity);
      acumTotal += Number(element.totalCost);
      return element;

    });
    setProductsTransfer(newProducts);
    setProductsTransferUniv(newProducts);
    setTransfer({...transfer,
      totalQuantity: acumQuantity,
      subTotal: acumTotal,
      total: acumTotal
    });
  };

  const deleteProduct = (productElement) => {
    const newProductsFound = productsTransfer.filter(elmntProd => (`${elmntProd._id}${elmntProd.rack}` !== `${productElement._id}${productElement.rack}`));
    let acumQuantity = 0;
    let acumSubtotal = 0;
    let acumTotal = 0;
    const newProductsAux = newProductsFound.map((prdElm) => {
      acumQuantity += prdElm.quantity;
      acumSubtotal += (prdElm.quantity * prdElm.defaultCost);
      acumTotal += (prdElm.quantity * prdElm.defaultCost);
      return prdElm;
    });

    setProductsTransfer(newProductsAux);
    setProductsTransferUniv(newProductsAux);
    setTransfer({...transfer,
      totalQuantity: acumQuantity,
      subTotal: acumTotal,
      total: acumTotal
    });
  };

  const handleChange = (valValue, nameValue) => {
    if (nameValue === "date" && transfer.expectedBy !== "") {
      const formattedExpectedBy = new Date(transfer.expectedBy);
      const result = compareDates(valValue, formattedExpectedBy);

      if (result <= 0) {
        setTransfer({...transfer, [nameValue]: valValue});
      } else {
        toast.warning("The expected date must be greater than the date", alertOptions);
      }
    } else if (nameValue === "expectedBy" && transfer.date !== "") {
      const formattedDate = new Date(transfer.date);
      const result = compareDates(valValue, formattedDate);

      if (result >= 0) {
        setTransfer({...transfer, [nameValue]: valValue});
      } else {
        toast.warning("The expected date must be greater than the date", alertOptions);
      }
    } else if (nameValue === "fromWarehouse") {
      const foundAllWH = warehouses.filter(element => !element.deleted && element._id !== valValue);
      setWarehouseOptions(foundAllWH);
      setTransfer({...transfer, fromWarehouse: valValue, toWarehouse: ""});
    } else {
      setTransfer({...transfer, [nameValue]: valValue});
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (
      transfer.status !== "" && transfer.date !== "" && transfer.expectedBy !== "" && transfer.fromWarehouse !== "" &&
            transfer.fees !== "" && transfer.toWarehouse !== "" && transfer.freight !== "" && productsTransfer.length > 0
    ) {
      if ((formatDate(new Date(transfer.date)) >= formatDate(new Date())) || (formatDate(new Date(transfer.expectedBy)) >= formatDate(new Date()))) {
        const transferModel = {
          date: transfer.date,
          expectedBy: transfer.expectedBy,
          fromWarehouse: { _id: transfer.fromWarehouse },
          toWarehouse: { _id: transfer.toWarehouse },
          fees: transfer.fees,
          freight: transfer.freight,
          status: transfer.status,
          totalQuantity: transfer.totalQuantity,
          subTotal: transfer.subTotal,
          total: transfer.total,
          message: transfer.message,
        };

        const productsFoundZero = productsTransfer.filter(element => element.quantity === 0);
        if (productsFoundZero.length === 0) {
          const productsWrongQty = productsTransfer.filter(element => (element?.data?.quantity < element?.quantity));
          if (productsWrongQty.length === 0) {
            const result = validateInventoryTransfer(transferModel);
            if (result.status) {
              setLoader(true);
              if (params && params.id) {
                transferModel._id = params.id;
                transferModel.updateBy = { _id: dataSession._id };
                transferModel.productsInventory = productsTransfer.map((transferElement) => {
                  return ({
                    productInventory: { _id: transferElement.productInventory ? transferElement.productInventory._id : transferElement._id },
                    rack: { _id: transferElement.rack._id ? transferElement.rack._id : transferElement.rack  },
                    quantity: transferElement.quantity,
                    defaultCost: transferElement.defaultCost,
                    totalCost: transferElement.totalCost
                  });
                });
                updateInventoryTransferId(params.id, transferModel).then(res => {
                  if (res.status === 200) {
                    toast.success("Transfer updated successfully", alertOptions);
                    setLoader(false);
                    navigate("/inventory/transfer");
                  } else {
                    setLoader(false);
                    toast.warning(res.response.data.message, alertOptions);
                  }
                }).catch(error => {
                  setLoader(false);
                  toast.warning(error.response.data.message, alertOptions);
                });
              } else {
                transferModel.productsInventory = productsTransfer.map((transferElement) => {
                  return ({
                    productInventory: { _id: transferElement._id },
                    rack: { _id: transferElement.rack },
                    quantity: transferElement.quantity,
                    defaultCost: transferElement.defaultCost,
                    totalCost: transferElement.totalCost
                  });
                });
                transferModel.cratedBy = { _id: dataSession._id };
                saveInventoryTransfer(transferModel).then(res => {
                  if (res.status === 200) {
                    toast.success("Transfer success", alertOptions);
                    setLoader(false);
                    navigate("/inventory/transfer");
                  } else {
                    setLoader(false);
                    toast.warning(res.response.data.message, alertOptions);
                  }
                }).catch(error => {
                  setLoader(false);
                  toast.warning(error.response.data.message, alertOptions);
                });
              }
            } else {
              toast.warning(result.msg, alertOptions);
            }
          } else {
            toast.warning("Please review the products to be transferred with erroneous amounts", alertOptions);
          }
        } else {
          toast.warning("Please check that there is no product with a quantity of 0", alertOptions);
        }
      } else {
        toast.warning("Cannot select a date earlier (date or expected by) than now", alertOptions);
      }
    } else {
      toast.info("Please fill in all the fields of the form", alertOptions);
    }
  };

  const getData = async (idTransfer, allwh) => {
    await getInventoryTransferId(idTransfer).then(async res => {
      if (res.status === 200) {
        let newProducts = res.data.productsTransfer && res.data.productsTransfer.length > 0 ? res.data.productsTransfer : [];
        for (let i = 0; i < newProducts.length; i++) {
          const resQtyStock = await getInventoryAddWithRacks(newProducts[i].productInventory._id);
          newProducts[i].data = { productInventory: newProducts[i].productInventory };
          newProducts[i].data.quantity = resQtyStock.status === 200 ? resQtyStock.data.totalQuantity : 0;
        }

        if (res.data?.fromWarehouse) {
          const foundAllWH = allwh.filter(element => !element.deleted && element._id !== res.data.fromWarehouse._id);
          setWarehouseOptions(foundAllWH);
        }
        setProductsTransfer(newProducts);
        setProductsTransferUniv(newProducts);
        setTransfer({...transfer,
          _id: res.data._id,
          status: res.data.status,
          date: formatReverseDate(res.data.date),
          expectedBy: formatReverseDate(res.data.expectedBy),
          fromWarehouse: res.data.fromWarehouse ? res.data.fromWarehouse._id : "",
          fees: res.data.fees,
          toWarehouse: res.data.toWarehouse ? res.data.toWarehouse._id : "",
          freight: res.data.freight,
          productsInventory: [],
          totalQuantity: res.data.totalQuantity,
          subTotal: res.data.subTotal,
          total: res.data.total,
          message: res.data.message,
          cratedBy: res.data.cratedBy,
          movements: res.data?.movementsTransfer
        });
      }
    });
  };

  useEffect(() => {
    getWarehouses().then(res => {
      if (res.status === 200 && res.data.length > 0) {
        setWarehouses(res.data);
        if (dataSession.userType === "ADMIN") {
          const warehousesFromCurrent = res.data.filter(element => !element.deleted);
          setWarehouseFromOptions(warehousesFromCurrent);
        } else {
          const warehousesFromCurrent = res.data.filter(element => {
            const foundAllWH = dataSession?.allWarehouse.find(elemWH => elemWH._id === element._id);
            if (!element.deleted && foundAllWH) return element;
          });
          setWarehouseFromOptions(warehousesFromCurrent);
        }
      } else {
        setWarehouses([]);
        setWarehouseFromOptions([]);
      }
      if (params && params.id) getData(params.id, res.data);
    }).catch(() => {
      if (params && params.id) getData(params.id, []);
    });
  }, []);

  return (
    <>
      { loader && <Loader /> }

      <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
        <div className="grid grid-cols-12 px-0 md:px-5">
          <div>
            <Link to="/inventory/transfer">
              <button className="p-1 rounded-xl" type="button">
                <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
              </button>
            </Link>
          </div>
          <div className="flex justify-center items-center col-span-11 text-center">
            <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:mr-[8%]">
              { params.id ? `TRANSFER #TF-${String(params.id).substring(String(params.id).length - 5).toLocaleUpperCase()}` : "NEW TRANSFER" }
            </h1>
          </div>
        </div>
        <div className="px-0 md:px-5">
          <FormTransfer
            headerTransferData={transfer}
            statusOptions={statusOptions}
            warehousesOptions={warehouseOptions}
            warehouseFromOptions={warehouseFromOptions}
            onHandleChange={handleChange}
            onlyRead={false}
          />
        </div>
        <div className="px-0 md:px-5">
          <Filters onHandleChange={handleFilters} />
        </div>
        <div className="px-0 md:px-5 overflow-x-auto">
          <TableSentProducts
            products={productsTransfer}
            quantity={transfer.totalQuantity}
            subtotal={transfer.subTotal}
            total={transfer.total}
            onProduct={handleProduct}
            onDelete={deleteProduct}
            onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
          />
        </div>
        <div className="px-0 md:px-5">
          <div className="pb-5 border-b-2 border-dashed border-ag-secondary-light">
            <button
              className="bg-ag-secondary-dark text-white text-xs lg:text-base px-6 py-2 rounded-xl"
              type="button"
              onClick={() => {
                if (transfer.fromWarehouse === "") {
                  toast.warning("Please select a warehouse first", alertOptions);
                } else {
                  setShowModal({...showModal, data: transfer.fromWarehouse, status: true});
                }
              }}
            >
              Add Products
            </button>
          </div>
        </div>
        <div className="px-0 md:px-5">
          <div className="py-3 space-y-5 pb-5 border-b-2 border-dashed border-ag-secondary-light">
            <div className="w-full">
              <h1 className="text-xs lg:text-base">MESSAGE TO RECEIVING WAREHOUSE</h1>
            </div>
            <div className="w-full">
              <textarea
                className="w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-100"
                rows="6"
                placeholder="Insert message to Warehouse..."
                name="message"
                onChange={(evt) => handleChange(evt.target.value, evt.target.name)}
                value={transfer.message}
              >
              </textarea>
            </div>
          </div>
        </div>
        <div className="px-0 md:px-5">
          <div className="py-3 space-y-5 pb-5 border-b-2 border-dashed border-ag-secondary-light">
            <div className="w-full">
              <h1 className="text-xs lg:text-base">HISTORY</h1>
            </div>
            <div className="w-full">
              <TableHistoryTransfer movements={transfer.movements} />
            </div>
          </div>
        </div>
        <div className="px-0 md:px-5 flex justify-between items-center">
          <button
            className="w-[40%] md:w-[20%] p-2 bg-ag-secondary-dark text-white text-xs lg:text-base rounded-xl"
            type="button"
            onClick={() => {
              setShowConfirmModal({...showConfirmModal,
                status: true,
                title: "Are you sure you want to discard the changes?",
                element: ""
              });
            }}
          >
            Close
          </button>
          <button
            className="w-[40%] md:w-[20%] p-2 bg-ag-primary-dark text-white text-xs lg:text-base rounded-xl"
            type="button"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </div>

      {
        showConfirmModal.status ?
          <Modal
            title={showConfirmModal.title}
            onClose={() => { setShowConfirmModal({...showConfirmModal, status: false, title: "", element: null}); }}
            onResponse={(sts) => {
              if (sts) {
                navigate("/inventory/transfer");
              }
            }}
          />
          : null
      }

      { showModal.status ?
        <ProductsAvailable
          typeView="transfer"
          IdWarehouse={showModal.data}
          currentProducts={productsTransfer}
          onProducts={onResponseProducts}
          onClose={() => setShowModal({ data: "", status: false })}
          onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
        />
        :
        null
      }

      {
        showCompatible.status ?
          <ModalCompatible
            products={showCompatible.element}
            onClose={() => setShowCompatible({...showCompatible, status: false, element: null})}
          />
          : null
      }
    </>
  );
};

export default SaveTransfer;