import { forwardRef, useEffect, useRef, useState } from "react";
import { formatReverseDate, formatDate } from "../../../utils/Utilities";
import DatePicker from "react-datepicker";
import { FaFilter } from "react-icons/fa";

const INITIAL_STATE = {
  search: "",
  typeUser: "",
  year: "",
  month: "",
  phoneNumber: "",
  startDate: "",
  endDate: "",
  typeOrder: ""
};

const Filters = ({ enableFilters, typeOptions, yearsOptions, monthsOptions, isClean, onClean, onFilters }) => {
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const [buttonSel, setButtonSel] = useState({ btn1: false, btn2: false });
  const [focusClass, setFocusClass] = useState({
    search: { placeholder: "Search", status: false },
    typeUser: { placeholder: "Type", status: false },
    year: { placeholder: "Year", status: false },
    month: { placeholder: "Month", status: false },
    phoneNumber: { placeholder: "Phone Number", status: false },
    startDate: { placeholder: "Start date", status: false },
    endDate: { placeholder: "End date", status: false },
  });

  const [options, setOptions] = useState(INITIAL_STATE);
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);


  const clearFilters = () => {
    setOptions(INITIAL_STATE);
    onFilters(options.search, INITIAL_STATE, false);
  };

  const handleChange = (evt, nameInpt) => {
    if (nameInpt === "startDate" || nameInpt === "endDate") {
      if (nameInpt === "startDate") {
        setOpenStartDate(!openStartDate);
      } else {
        setOpenEndDate(!openEndDate);
      }

      if (evt === "default") {
        setOptions({...options, [nameInpt]: "", year: "", month: ""});
      } else {
        setOptions({...options, [nameInpt]: evt, year: "", month: ""});
      }

      setFocusClass({...focusClass,
        year: { placeholder: "Year", status: false },
        month: { placeholder: "Month", status: false },
      });

      return;
    }

    const { name, value } = evt.currentTarget;

    if (name === "search") {
      setOptions ({...options, [name]: value});
    } else {
      if (name === "year" || name === "month") {
        if (value === "default") {
          setOptions({...options, [name]: "", startDate: "", endDate: ""});
        } else {
          setOptions({...options, [name]: value, startDate: "", endDate: ""});
        }

        setFocusClass({...focusClass,
          startDate: { placeholder: "Start date", status: false },
          endDate: { placeholder: "End date", status: false }
        });
      } else {
        if (value === "default") {
          setOptions({...options, [name]: ""});
        } else {
          setOptions({...options, [name]: value});
        }
      }
    }
  };

  const handleChangeBtn = (btnSel) => {
    if (btnSel === "mayorpurchases") {
      if (!buttonSel.btn1) {
        setButtonSel({...buttonSel, btn1: true, btn2: false});
        setOptions({...options, typeOrder: "mayorpurchases"});
      } else {
        setButtonSel({...buttonSel, btn1: false, btn2: false});
        setOptions({...options, typeOrder: ""});
      }
    } else {
      if (!buttonSel.btn2) {
        setButtonSel({...buttonSel, btn1: false, btn2: true});
        setOptions({...options, typeOrder: "minorpurchases"});
      } else {
        setButtonSel({...buttonSel, btn1: false, btn2: false});
        setOptions({...options, typeOrder: ""});
      }
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    onFilters(options.search, {...options,
      startDate: options.startDate !== "" ? formatReverseDate(options.startDate) : "",
      endDate: options.endDate !== "" ? formatReverseDate(options.endDate) : ""
    }, false);
  };

  const handleClickOutside = (event) => {
    if (endDateRef.current && !endDateRef.current.contains(event.target)) {
      setOpenEndDate(false);
    }

    if (startDateRef.current && !startDateRef.current.contains(event.target)) {
      setOpenStartDate(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    if (isClean) {
      setOptions(INITIAL_STATE);

      setFocusClass({...focusClass,
        search: { placeholder: "Search", status: false },
        typeUser: { placeholder: "Type", status: false },
        year: { placeholder: "Year", status: false },
        month: { placeholder: "Month", status: false },
        phoneNumber: { placeholder: "Phone Number", status: false },
        startDate: { placeholder: "Start date", status: false },
        endDate: { placeholder: "End date", status: false },
      });

      setButtonSel({...buttonSel, btn1: false, btn2: false});
      onClean(false);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isClean]);

  const CustomDatePicker = forwardRef(({ value, onClick, className, placeholderText, onChange }, ref) => (
    <button className={className} onClick={onClick} onChange={onChange} ref={ref} placeholder={placeholderText} >
      {value}
    </button>
  ));

  CustomDatePicker.displayName = "CustomDatePicker";

  return (
    <div className="flex justify-center">
      <form onSubmit={handleSubmit}>
        <div className="flex-col lg:flex-row flex gap-[20px]">
          <div>
            <div>
              <input
                className={"w-full text-ag-secondary-letter text-sm lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate text-left"}
                type="text"
                name="search"
                placeholder={focusClass.search.placeholder}
                onChange={(evt) => handleChange(evt, "")}
                disabled={!enableFilters}
                onFocus={() => setFocusClass({...focusClass, search: { placeholder: "", status: true }})}
                onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, search: { placeholder: "Search", status: false }}) : null}
                maxLength={60}
                value={options.search}
              />
            </div>

            <div className="mt-[10px] gap-[10px] mb-[100px] grid grid-cols-2 md:mb-[0px] md:mt-[15px] md:flex md:gap-[15px] h-[30px]">
              <div>
                <select
                  className={`text-xs h-full lg:text-sm text-ag-secondary-letter border border-gray-500 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.typeUser.status ? "text-left" : "text-center"}`}
                  name="typeUser"
                  defaultValue="default"
                  onChange={(evt) => handleChange(evt, "")}
                  disabled={!enableFilters}
                  onFocus={() => setFocusClass({...focusClass, typeUser: { placeholder: "", status: true }})}
                  onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, typeUser: { type: "Type", status: false }}) : null}
                  value={options.typeUser}
                >
                  <option value="default">Type</option>
                  {
                    typeOptions.map((element, index) => {
                      return <option key={index} value={element.value}>{element.label}</option>;
                    })
                  }
                </select>
              </div>

              <div>
                <select
                  className={`text-xs h-full lg:text-sm text-ag-secondary-letter border border-gray-500 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.year.status ? "text-left" : "text-center"}`}
                  name="year"
                  defaultValue="default"
                  onChange={(evt) => handleChange(evt, "")}
                  disabled={!enableFilters}
                  onFocus={() => setFocusClass({...focusClass, year: { placeholder: "", status: true }})}
                  onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, year: { placeholder: "Year", status: false }}) : null}
                  value={options.year}
                >
                  <option value="default">Year</option>
                  {
                    yearsOptions.map((element, index) => {
                      return <option key={index} value={element.value}>{element.label}</option>;
                    })
                  }
                </select>
              </div>

              <div>
                <select
                  className={`text-xs h-full lg:text-sm text-ag-secondary-letter border border-gray-500 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.month.status ? "text-left" : "text-center"}`}
                  name="month"
                  defaultValue="default"
                  onChange={(evt) => handleChange(evt, "")}
                  disabled={!enableFilters}
                  onFocus={() => setFocusClass({...focusClass, month: { placeholder: "", status: true }})}
                  onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, month: { placeholder: "Month", status: false }}) : null}
                  value={options.month}
                >
                  <option value="default">Month</option>
                  {
                    monthsOptions.map((element, index) => {
                      return <option key={index} value={element.value}>{element.label}</option>;
                    })
                  }
                </select>
              </div>

              <div>
                <input
                  className={`text-xs h-full lg:text-sm text-ag-secondary-letter border border-gray-500 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.phoneNumber.status ? "text-left" : "text-center"}`}
                  type="text"
                  name="phoneNumber"
                  placeholder={focusClass.phoneNumber.placeholder}
                  onChange={(evt) => handleChange(evt, "")}
                  disabled={!enableFilters}
                  onFocus={() => setFocusClass({...focusClass, phoneNumber: { placeholder: "", status: true }})}
                  onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, phoneNumber: { placeholder: "Phone Number", status: false }}) : null}
                  maxLength={10}
                  value={options.phoneNumber}
                />
              </div>

              <div
                className="static"
                ref={startDateRef}
              >
                <button
                  onClick={() => setOpenStartDate(!openStartDate)}
                  type="button"
                  className={"px-[10px] h-[30px] text-xs lg:text-sm text-ag-secondary-letter border border-gray-500 rounded-xl disabled:bg-zinc-200 truncate text-center"}
                >
                  {options.startDate ? formatDate(options.startDate) : 'Start Date'}
                </button>

                {
                  openStartDate && (
                    <div className="absolute">
                      <DatePicker
                        selected={options.startDate}
                        onChange={(date) => handleChange(date, "startDate")}
                        inline
                      />
                    </div>
                  )
                }
              </div>

              <div
                className="static"
                ref={endDateRef}
              >
                <button
                  onClick={() => setOpenEndDate(!openEndDate)}
                  className={"px-[10px] h-[30px] text-xs lg:text-sm text-ag-secondary-letter border border-gray-500 rounded-xl disabled:bg-zinc-200 truncate text-center"}
                  type="button"
                >
                  {options.endDate ? formatDate(options.endDate) : 'End Date'}
                </button>

                {
                  openEndDate && (
                    <div className="absolute">
                      <DatePicker
                        selected={options.endDate}
                        onChange={(date) => handleChange(date, "endDate")}
                        inline
                      />
                    </div>
                  )
                }
              </div>

              <div>
                <button
                  className={`truncate px-[10px] h-[30px] text-center text-xs lg:text-sm text-ag-secondary-letter border border-gray-500 rounded-xl disabled:bg-zinc-200 ${buttonSel.btn1 ? "bg-zinc-200" : ""}`}
                  type="button"
                  disabled={!enableFilters}
                  onClick={() => handleChangeBtn("mayorpurchases")}

                >
                  Mayor purchases
                </button>
              </div>

              <div>
                <button
                  className={`truncate px-[10px] h-[30px] text-center text-xs lg:text-sm text-ag-secondary-letter border border-gray-500 rounded-xl disabled:bg-zinc-200 ${buttonSel.btn2 ? "bg-zinc-200" : ""}`}
                  type="button"
                  disabled={!enableFilters}
                  onClick={() => handleChangeBtn("minorpurchases")}
                >
                  Minor purchases
                </button>
              </div>
            </div>
          </div>

          <div>
            <button
              className="mb-[15px] w-full bg-ag-secondary-light hover:bg-[#74787f] text-white text-sm lg:text-base px-[24px] py-[8px] rounded-[5px] disabled:bg-zinc-200"
              type="submit"
              disabled={!enableFilters}
            >
              <div className="flex justify-center items-center gap-[15px]">
                <span>
                    Filter
                </span>

                <FaFilter />
              </div>
            </button>

            <button
              className="w-full bg-ag-secondary-light hover:bg-[#74787f] text-white text-sm lg:text-base px-[24px] py-[8px] rounded-[5px] disabled:bg-zinc-200"
              disabled={!enableFilters}
              type="button"
              onClick={clearFilters}
            >
                Clear Filters
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Filters;