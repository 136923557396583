import { useEffect, useState } from "react";

const Filters = ({ enableFilters, enableAddRetail, enableAddWholesaler, typeOptions, onFilters, onAdd }) => {
  const [enableAdd, setEnableAdd] = useState(true);

  const [focusClass, setFocusClass] = useState({
    search: { placeholder: "Search", status: false },
    type: { placeholder: "Type", status: false },
    phoneNumber: { placeholder: "Phone Number", status: false }
  });

  const [options, setOptions] = useState({
    type: "",
    phoneNumber: ""
  });

  const [search, setSearch] = useState('');

  const handleChange = (evt) => {
    if (evt.currentTarget.name === "search") {
      onFilters(evt.currentTarget.value, "filters");
      setSearch(evt.currentTarget.value);
    } else {
      setSearch('');
      if (evt.currentTarget.value === "default") {
        setOptions({...options, [evt.currentTarget.name]: ""});
      } else {
        setOptions({...options, [evt.currentTarget.name]: evt.currentTarget.value});
      }
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setSearch('');
    onFilters(options, "options");
  };

  useEffect(() => {
    if (enableAddRetail || enableAddWholesaler) {
      setEnableAdd(false);
    }
  }, [enableAddRetail, enableAddWholesaler]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="space-y-2 md:flex md:justify-center md:items-center md:space-x-8 md:space-y-0">
          <div className="w-full space-y-3 lg:space-y-6">
            <div>
              <input
                className={`w-full text-ag-secondary-letter text-sm lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.search.status ? "text-left" : "text-center"}`}
                type="text"
                name="search"
                placeholder={focusClass.search.placeholder}
                value={search}
                onChange={handleChange}
                disabled={!enableFilters}
                onFocus={() => setFocusClass({...focusClass, search: { placeholder: "", status: true }})}
                onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, search: { placeholder: "Search", status: false }}) : null}
              />
            </div>

            <div className="grid gap-3 lg:gap-6 md:grid-cols-5">
              <div>
                <select
                  className={`w-full text-sm p-1 text-ag-secondary-letter border border-gray-500 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.type.status ? "text-left" : "text-center"}`}
                  name="type"
                  defaultValue="default"
                  onChange={handleChange}
                  disabled={!enableFilters}
                  onFocus={() => setFocusClass({...focusClass, type: { placeholder: "", status: true }})}
                  onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, type: { placeholder: "Type", status: false }}) : null}
                >
                  <option value="default">Type</option>
                  {
                    typeOptions.map((element, index) => {
                      return <option key={index} value={element.value}>{element.label}</option>;
                    })
                  }
                </select>
              </div>

              <div className="md:col-span-2">
                <input
                  className={`w-full text-sm p-1 text-ag-secondary-letter border border-gray-500 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.phoneNumber.status ? "text-left" : "text-center"}`}
                  type="text"
                  name="phoneNumber"
                  placeholder={focusClass.phoneNumber.placeholder}
                  onChange={handleChange}
                  disabled={!enableFilters}
                  onFocus={() => setFocusClass({...focusClass, phoneNumber: { placeholder: "", status: true }})}
                  onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, phoneNumber: { placeholder: "Phone Number", status: false }}) : null}
                />
              </div>

              <div className="col-span-2"></div>
            </div>
          </div>

          <div className="w-full md:w-[400px] space-y-3">
            <div>
              <button
                className="w-full bg-ag-primary-light text-white text-sm lg:text-base p-2 rounded-xl disabled:bg-zinc-200"
                type="button"
                onClick={() => onAdd()}
                disabled={enableAdd}
              >
                New record
              </button>
            </div>

            <div>
              <button
                className="w-full bg-ag-secondary-light text-white text-sm lg:text-base p-2 rounded-xl disabled:bg-zinc-200"
                type="submit"
                disabled={!enableFilters}
              >
                Filter
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Filters;