import { useState } from "react";
import { getProduct } from "../../../../services/Product";

const Filters = ({ enableFilters, productsOptions, monthOptions, yearOptions, setPrices, setPricesUniv, onFilters }) => {
  const [focusClass, setFocusClass] = useState({
    product: { placeholder: "Product", status: false },
    search: { placeholder: "Search...", status: false },
    year: { placeholder: "Year", status: false },
    month: { placeholder: "Month", status: false }
  });

  const [options, setOptions] = useState({
    search: "",
    year: "",
    month: ""
  });

  const handleChange = (evt) => {
    if (evt.currentTarget.name === "search") {
      setOptions({...options, [evt.currentTarget.name]: evt.currentTarget.value});
      onFilters(evt.currentTarget.value, options);
    } else {
      if (evt.currentTarget.value === "default") {
        setOptions({...options, [evt.currentTarget.name]: ""});
      } else {
        if (evt.currentTarget.name === "product") {
          getProduct(evt.currentTarget.value).then((res) => {

            if (res.status === 200) {
              let arrPriceHistory = [];
              if (res.data.priceHistory.length) {
                for (let i = 0; i < res.data.priceHistory.length; i++) {
                  arrPriceHistory.push({
                    nags: res.data.nags,
                    name: res.data.name,
                    description: res.data.description,
                    vendors: res.data.vendors,
                    note: res.data.note,
                    price: res.data.priceHistory[i].price,
                    barcode1: res.data.barcode1,
                    barcode2: res.data.barcode2,
                    totalQuantity: res.data.totalQuantity,
                    compatible_vehicles: res.data?.compatible_vehicles,
                    date: res.data.priceHistory[i].createdAt,
                    hour: res.data.priceHistory[i].createdAt,
                    role: res.data.by.role.name,
                    user: `${res.data.by.names} ${res.data.by.lastNames ? res.data.by.lastNames : ""}`,
                  });
                }
              } else {
                arrPriceHistory.push({
                  nags: res.data.nags,
                  name: res.data.name,
                  compatible_vehicles: res.data?.compatible_vehicles,
                  description: res.data.description,
                  vendors: res.data.vendors,
                  price: res.data.price,
                  note: res.data.note,
                  barcode1: res.data.barcode1,
                  barcode2: res.data.barcode2,
                  totalQuantity: res.data.totalQuantity,
                  date: res.data.createdAt,
                  hour: res.data.createdAt,
                  role: "",
                  user: res.data.by.names
                });
              }
              setPrices(arrPriceHistory);
              setPricesUniv(arrPriceHistory);
            }
          });
        } else {
          setOptions({...options, [evt.currentTarget.name]: evt.currentTarget.value});
        }
      }
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    onFilters(options.search, options);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="grid gap-2 lg:gap-10 md:grid-cols-3">
          <div className="space-y-2">
            <div>
              <select
                className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 py-2 px-4 rounded-xl truncate ${focusClass.product.status ? "text-left" : "text-center"}`}
                name="product"
                defaultValue="default"
                onChange={handleChange}
                onFocus={() => setFocusClass({...focusClass, product: { placeholder: "", status: true }})}
                onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, product: { placeholder: "Product", status: false }}) : null}
              >
                <option value="default">Product</option>
                {
                  productsOptions.map((element, index) => {
                    return <option key={index} value={element._id}>{element.name}</option>;
                  })
                }
              </select>
            </div>
            <div>
              <select
                className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 py-2 px-4 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.year.status ? "text-left" : "text-center"}`}
                name="year"
                defaultValue="default"
                onChange={handleChange}
                disabled={!enableFilters}
                onFocus={() => setFocusClass({...focusClass, year: { placeholder: "", status: true }})}
                onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, year: { placeholder: "Year", status: false }}) : null}
              >
                <option value="default">Year</option>
                {
                  yearOptions.map((element, index) => {
                    return <option key={index} value={element.value}>{element.label}</option>;
                  })
                }
              </select>
            </div>
          </div>
          <div className="space-y-2">
            <div>
              <input
                className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 py-2 px-4 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.search.status ? "text-left" : "text-center"}`}
                type="text"
                name="search"
                placeholder={focusClass.search.placeholder}
                onChange={handleChange}
                disabled={!enableFilters}
                onFocus={() => setFocusClass({...focusClass, search: { placeholder: "", status: true }})}
                onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, search: { placeholder: "Search...", status: false }}) : null}
                maxLength={60}
              />
            </div>
            <div>
              <select
                className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 py-2 px-4 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.month.status ? "text-left" : "text-center"}`}
                name="month"
                defaultValue="default"
                onChange={handleChange}
                disabled={!enableFilters}
                onFocus={() => setFocusClass({...focusClass, month: { placeholder: "", status: true }})}
                onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, month: { placeholder: "Month", status: false }}) : null}
              >
                <option value="default">Month</option>
                {
                  monthOptions.map((element, index) => {
                    return <option key={index} value={element.value}>{element.label}</option>;
                  })
                }
              </select>
            </div>
          </div>
          <div className="space-y-3">
            <div>
              <button
                className="w-full bg-ag-primary-light text-white text-xs lg:text-base p-2 rounded-xl disabled:bg-zinc-200"
                type="submit"
                disabled={!enableFilters}
                onChange={handleChange}
              >
                                Filter
              </button>
            </div>
            <div>

            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Filters;