import { useContext, useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { cancelDeliveryOrder, checkDeliveryOrder, deliveryOrder, getOrder, paiCloverdOrder } from "../../../../services/Orders";
import { toast } from "react-toastify";
import { alertOptions, removeAccents } from "../../../../utils/Utilities";
import { saveReturn } from "../../../../services/Returns";
import { searchProductEspecification } from "../../../../services/Product";
import { PermitsContext } from "../../../../services/Permits";
import Loader from "../../../../components/loader/Loader";
import HeaderDetails from "../details/HeaderDetails";
import TableDelivery from "./TableDelivery";
import ModalInput from "../../../../components/modal/ModalInput";
import ModalInputNumber from "../../../../components/modal/ModalNumber";
import ModalCompatible from "../../products/ModalCompatible";
import MyCartFeatures from "../../../wholesaler/mycart/MyCartFeatures";
import ModalDelivery from "./ModalDelivery";
import ModalSignature from "../../../../components/modal/ModalSignature";

const DeliveryOrders = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { dataSession } = useContext(PermitsContext);
  const [loader, setLoader] = useState(true);
  const [showDelivery, setShowDelivery] = useState(false);
  const [showConfirm, setShowConfirm] = useState({ status: false, msg: "" });
  const [showConfirmNumber, setShowConfirmNumber] = useState({ status: false, msg: "" });
  const [showConfirmSignature, setShowConfirmSignature] = useState({ status: false, msg: "" });
  const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
  const [showFeatures, setShowFeatures] = useState({ status: false, element: null });
  const [order, setOrder] = useState({
    _id: "",
    client: "",
    date: "",
    employee: "",
    orderType: "",
    payType: "",
    productsOrder: [],
    status: "",
    subTotal: "",
    total: "",
    ticketClover: "",
    warehouse: ""
  });

  const handleItem = (evt, item) => {
    const newProductsOrder = order.productsOrder.map(element => {
      if (item._id === element._id) {
        return {...element, [evt.target.name]: evt.target.value};
      }
      return element;
    });
    setOrder({...order, productsOrder: newProductsOrder});
  };

  const handleCheck = (item) => {
    if (item?.check && item?.qtyDefault === item?.real) {
      toast.info("The check has already been carried out previously, for the current entered value", alertOptions);
    } else {
      if (item.hasOwnProperty("real") && (Number(item.real) !== item.quantity) && (!item.hasOwnProperty("note") || item.note === "")) {  // eslint-disable-line no-prototype-builtins
        toast.info("If there is a discrepancy, a note should be added", alertOptions);
      } else if (!item.hasOwnProperty("real") && !item.hasOwnProperty("note")) {  // eslint-disable-line no-prototype-builtins
        toast.info("Please fill in all the fields of the form", alertOptions);
      } else {
        if (!isNaN(item.real) && item.real > 0) {
          setLoader(true);
          const checkModel = {
            order: { _id: params.id },
            productOrder: {
              _id: item._id,
              check: true,
              real: item?.real,
              discrepancy: Number(item.quantity) - Number(item?.real),
              note: item?.note
            }
          };
          checkDeliveryOrder(params.id, checkModel).then(res => {
            if (res.status === 200) {
              const newProducts = order.productsOrder.map(elem => {
                if (elem?._id === item?._id) return {...elem, check: true, qtyDefault: item?.real};
                return elem;
              });
              setOrder({...order, productsOrder: newProducts});
              toast.success("Item checked successfully", alertOptions);
              setLoader(false);
            } else {
              setLoader(false);
              toast.warning(res.response.data.message ? res.response.data.message : "There was a problem checking the item", alertOptions);
            }
          }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
          });
        } else {
          toast.warning("Please check that the real field is a numeric value", alertOptions);
        }
      }
    }
  };

  const handleCancel = () => {
    setLoader(true);
    cancelDeliveryOrder(params.id, { _id: params.id, status: "attend" }).then(res => {
      if (res.status === 200) {
        toast.success("Status changed successfully", alertOptions);
        navigate("/orders");
      } else {
        toast.warning(res.response.data.message ? res.response.data.message : "There was a problem canceling the order", alertOptions);
      }
      setLoader(false);
    }).catch(error => {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    });
  };

  const handleShowModal = () => {
    if (order?.orderType === "POWER_WINDOW_REPAIR" || order?.orderType === "WINDOW_TINT") {
      setShowDelivery(true);
    } else {
      const foundProductsWithoutCheck = order.productsOrder.filter(element => !element.check);
      if (foundProductsWithoutCheck.length === 0) {
        setShowDelivery(true);
      } else {
        toast.warning("Please check the all products before delivery", alertOptions);
      }
    }
  };

  const handleInput = (type) => {
    setShowDelivery(false);
    if (type === "name") {
      setShowConfirm({...showConfirm,
        status: true,
        msg: "Please enter customer's first name for delivery"
      });
    } else if(type === "phoneNumber") {
      setShowConfirmNumber({...showConfirmNumber,
        status: true,
        msg: "Please enter the customer's phone number for delivery"
      });
    }else{
      setShowConfirmSignature({...showConfirmSignature,
        status: true,
        msg: "Enter your signature for delivery"
      });
    }
  };

  const handleConfirmDelivery = (nameInput, valueInput) => {
    setLoader(true);
    if (nameInput === "name") {
      let palabra = new RegExp(`${removeAccents(order?.client?.names)}.*`, "i");
      if (palabra.test(valueInput)) {
        setShowConfirm({...showConfirm, status: false, msg: "" });
        setShowConfirmNumber({...showConfirmNumber, status: false, msg: "" });
        let body = {
          _id: params.id,
          status:"delivered",
          deliveryValidationType: "FIRST_NAME"
        };
        handleDelivery(body);
      } else {
        setLoader(false);
        toast.warning("Your first name is incorrect, please try again", alertOptions);
      }
    } else if(nameInput === "phoneNumber") {
      if (valueInput === order?.client?.phoneNumber) {
        setShowConfirm({...showConfirm, status: false, msg: "" });
        setShowConfirmNumber({...showConfirmNumber, status: false, msg: "" });
        let body = {
          _id: params.id,
          status:"delivered",
          deliveryValidationType: "PHONE"
        };
        handleDelivery(body);
      } else {
        setLoader(false);
        toast.warning("Your phone number is incorrect, please try again", alertOptions);
      }
    }else{
      setShowConfirmSignature({ status: false, msg: "" });
      let body = {
        _id: params.id,
        status:"delivered",
        deliveryValidationType: "SIGNATURE",
        signature: valueInput
      };
      handleDelivery(body);
    }
  };

  const handleDelivery = (body = {}) => {
    deliveryOrder(params.id, body).then(res => {
      if (res.status === 200) {
        //VALIDAR QUE NO SEA UNA ORDEN MINORISTA ES DECIR MAYORISTAS (PURCHASE O SALES)
        if (order.orderType !== "QUOTES" && order.orderType !== "POWER_WINDOW_REPAIR" && order.orderType !== "WINDOW_TINT") {
          paiCloverdOrder(params.id, { status: "paid" }).then(resTC => {
            toast.success("Order delivered successfully", alertOptions);
            navigate("/orders");
          }).catch((errorTC) => {
            toast.warning(errorTC.response.data.message, alertOptions);
          });
        } else {
          toast.success("Order delivered successfully", alertOptions);
          navigate("/orders");
        }
      } else {
        toast.warning(res.response.data.message ? res.response.data.message : "There was a problem delivering the order", alertOptions);
      }
      setLoader(false);
    }).catch(error => {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    });
  };

  const handleReturn = (returnModel, realValue, discValue) => {
    setLoader(true);
    returnModel.warehouse = { _id: order.warehouse };
    returnModel.order = { _id: order._id };
    returnModel.wholesaler = { _id: order.client?._id };
    returnModel.requestedBy = { _id: dataSession._id };
    saveReturn(returnModel).then(res => {
      if (res.status === 200) {
        const checkModel = {
          order: { _id: params.id },
          productOrder: {
            _id: returnModel.productOrder?._id,
            check: true,
            real: realValue,
            discrepancy: discValue,
            note: returnModel?.reason
          }
        };
        checkDeliveryOrder(checkModel).then();
        getOrderData();
        toast.success("Order returned and checked successfully", alertOptions);
      } else {
        toast.warning(res.response.data.message ? res.response.data.message : "There was a problem delivering the order", alertOptions);
      }
      setLoader(false);
    }).catch(error => {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    });
  };

  const getOrderData = async () => {
    await getOrder(params.id).then(async res => {
      if (res.status === 200) {
        let newProductsOrders = [];
        if (res.data?.orderType === "POWER_WINDOW_REPAIR" || res.data?.orderType === "WINDOW_TINT") {
          newProductsOrders.push(res.data?.anotherQuote);
        } else {
          for (let i = 0; i < res.data.productsOrder.length; i++) {
            let productsRes =  await searchProductEspecification({ nag: res.data.productsOrder[i].productInventory?.product?.nags });
            newProductsOrders.push({...res.data.productsOrder[i],
              compatible_vehicles: productsRes.status === 200 ? productsRes.data : [],
              qtyDefault: res.data.productsOrder[i]?.real ? res.data.productsOrder[i]?.real : res.data.productsOrder[i]?.quantity
            });
          }
        }

        setOrder({...order,
          _id: res.data._id,
          client: res.data.wholesalerUser ? res.data.wholesalerUser : res.data.retailUser,
          date: res.data.createdAt,
          employee: res.data.employee,
          orderType: res.data.orderType,
          payType: res.data.payType ? res.data.payType : res.data.payment,
          productsOrder: newProductsOrders,
          status: res.data.status,
          subTotal: res.data.subTotal,
          total: res.data.total,
          ticketClover: res.data?.orderIdClover ? res.data?.orderIdClover : "",
          warehouse: res.data?.warehouse?._id,
          po: res.data?.purchase?.po ? res.data?.purchase?.po : "- - - - -"
        });
      }
      setLoader(false);
    });
  };

  useEffect(() => {
    if (params && params.id) {
      setLoader(true);
      getOrderData();
    }
  }, []);

  return (
    <>
      { loader && <Loader /> }

      <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
        <div className="grid grid-cols-12 px-0 md:px-5">
          <div>
            <Link to="/orders">
              <button className="p-1 rounded-md" type="button">
                <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
              </button>
            </Link>
          </div>
          <div className="flex justify-center items-center col-span-11 text-center">
            <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:mr-[8%]">
                            Orders details
            </h1>
          </div>
        </div>
        <div className="pt-3">
          <HeaderDetails
            type="delivery"
            orderData={order}
            onCancel={handleCancel}
            onDelivery={() => handleShowModal()}
          />
        </div>
        <div className="text-center pt-3">
          <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">Line items</h1>
        </div>
        <div className="overflow-x-auto">
          <TableDelivery
            items={order.productsOrder}
            onItem={handleItem}
            onCheck={handleCheck}
            onReturn={handleReturn}
            onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
            onFeatures={(ftSel) => setShowFeatures({...showFeatures, status: true, element: ftSel})}
          />
        </div>
      </div>

      {
        showConfirm.status ?
          <ModalInput
            type="text"
            forDelivery={true}
            title={showConfirm.msg}
            onBack={() => {
              setShowConfirm({...showConfirm, status: false, msg: "" });
              setShowDelivery(true);
            }}
            onClose={() => {
              setShowConfirm({...showConfirm, status: false, msg: "" });
              setShowDelivery(false);
            }}
            onResponse={(valIpt) => handleConfirmDelivery("name", valIpt)}
          />
          : null
      }

      {
        showConfirmNumber.status ?
          <ModalInputNumber
            type="number"
            forDelivery={true}
            title={showConfirmNumber.msg}
            onClose={() => {
              setShowConfirmNumber({...showConfirmNumber, status: false, msg: "" });
              setShowDelivery(false);
            }}
            onBack={() => {
              setShowConfirmNumber({...showConfirmNumber, status: false, msg: "" });
              setShowDelivery(true);
            }}
            onResponse={(valIpt) => handleConfirmDelivery("phoneNumber", valIpt)}
          />
          : null
      }
      {
        showConfirmSignature.status ?
          <ModalSignature
            type="number"
            title={showConfirmSignature.msg}
            onClose={() => {
              setShowConfirmSignature({...showConfirmSignature, status: false, msg: "" });
              setShowDelivery(false);
            }}
            onBack = {() => {
              setShowConfirmSignature({...showConfirmSignature, status: false, msg: "" });
              setShowDelivery(true);
            }}
            onResponse={(valIpt) => handleConfirmDelivery("signature", valIpt)}
          />
          : null
      }

      {
        showCompatible.status ?
          <ModalCompatible
            products={showCompatible.element}
            onClose={() => setShowCompatible({...showCompatible, status: false, element: null})}
          />
          : null
      }

      {
        showFeatures.status ?
          <MyCartFeatures
            features={showFeatures.element}
            onClose={() => setShowFeatures({...showFeatures, status: false, element: null})}
          />
          : null
      }

      {
        showDelivery ?
          <ModalDelivery
            onInput={handleInput}
            onClose={() => setShowDelivery(false)}
          />
          : null
      }
    </>
  );
};

export default DeliveryOrders;