import { useContext, useEffect, useState } from "react";
import { getProducts, updateProduct } from "../../../services/Product";
import { PermitsContext } from "../../../services/Permits";
import { alertOptions } from "../../../utils/Utilities";
import { toast } from "react-toastify";
import Modal from "../../../components/modal/Modal";
import Filters from "./Filters";
import TableProduct from "./TableProduct";
import Loader from "../../../components/loader/Loader";
import ModalCompatible from "./ModalCompatible";

const Users = () => {
  const { dataSession, permits } = useContext(PermitsContext);

  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState({ status: false, element: null });
  const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
  const [updatePagination, setUpdatePagination] = useState(false);
  const [filterPagination, setFilterPagination] = useState({ sort: "DESC"});
  const [page, setPage] = useState(1);

  const [stateOptions] = useState([
    { label: "Active", value: "active" },
    { label: "Deactivate", value: "inactivated" }
  ]);

  const [products, setProducts] = useState([]);  const [totalCount, setTotalCount] = useState(0);

  const getData = (pge, filterPagination) => {
    setLoader(true);
    getProducts(pge, 10, filterPagination).then(res => {
      if (res.status === 200 && res.data.products.length > 0) {
        setProducts(res.data.products);
        setTotalCount(res.data.count);
        setUpdatePagination(true);
        setLoader(false);
      } else {
        setProducts([]);
        setTotalCount(0);
        setLoader(false);
      }
    });
  };
  const handleFilters = (wordFilter, type, value) => {
    if (type === "status") {
      let filterObject = {
        sort: "DESC"
      };
      for (const key in value) {
        if (Object.hasOwnProperty.call(value, key)) {
          if (value[key] !== "" && value[key] !== "default") filterObject[key] = value[key];
        }
      }
      getData(1, filterObject);
      setFilterPagination(filterObject);
    }

  };

  // const handleFilters = (wordFilter, type, value) => {
  //     if (wordFilter === "" && value.status === "" && value.barcode1 === "" && value.nags === "") {
  //         setProducts(productsUniv);
  //     } else {
  //         let productsFound = productsUniv;

  //         if (wordFilter !== "" && type === "search") {
  //             let palabra = new RegExp(`${removeAccents(wordFilter)}.*`, "i");
  //             productsFound = productsFound.filter(element => {
  //                 const foundVehicles = element.compatible_vehicles.filter(elemFound => (
  //                     palabra.test(elemFound?.year) ||
  //                     palabra.test(removeAccents(elemFound?.model)) ||
  //                     palabra.test(removeAccents(elemFound?.brand)) ||
  //                     palabra.test(removeAccents(elemFound?.type_car)) ||
  //                     palabra.test(removeAccents(elemFound?.type_glass)) ||
  //                     palabra.test(removeAccents(
  //                         `${String(elemFound?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(elemFound?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(elemFound?.year).replace(/\s+/g, '').split(',').sort()[Number(String(elemFound?.year).replace(/\s+/g, '').split(',').length-1)]}` : elemFound?.year}` +
  //                         ` ${elemFound?.brand} ${elemFound?.model} ${elemFound?.type_car}`
  //                     ))
  //                 ));
  //                 if (
  //                     palabra.test(removeAccents(element?.nags)) ||
  //                     palabra.test(removeAccents(element?.name)) ||
  //                     palabra.test(removeAccents(element?.model)) ||
  //                     palabra.test(removeAccents(element?.brand)) ||
  //                     palabra.test(element?.year) ||
  //                     palabra.test(removeAccents(element?.glassType)) ||
  //                     palabra.test(removeAccents(element?.type_car)) ||
  //                     palabra.test(removeAccents(element?.description)) ||
  //                     palabra.test(removeAccents(element?.note)) ||
  //                     palabra.test(element?.vendors && element?.vendors.length ? removeAccents(element?.vendors[0].name  ) : "") ||
  //                     palabra.test(element?.price) ||
  //                     palabra.test(removeAccents(element?.barcode1)) ||
  //                     palabra.test(removeAccents(element?.barcode2)) ||
  //                     palabra.test(element?.totalQuantity) ||
  //                     foundVehicles.length > 0
  //                 ) {
  //                     return element;
  //                 }
  //             });
  //         }

  //         if (value.status !== "") {
  //             productsFound = productsFound.filter(element =>
  //                 (value.status === "active" && (value.status === element.status || element.status === "true" || element.status === true)) ||
  //                 (value.status === "inactivated" && (value.status === element.status || element.status === "false" || element.status === false))
  //             );
  //         }

  //         if (value.barcode1 !== "" || (wordFilter !== "" && type === "barcode1")) {
  //             let palabra = new RegExp(`${removeAccents(wordFilter)}.*`, "i");
  //             productsFound = productsFound.filter(element => palabra.test(removeAccents(element?.barcode1)));
  //         }

  //         if (value.nags !== "" || (wordFilter !== "" && type === "nags")) {
  //             let palabra = new RegExp(`${removeAccents(wordFilter)}.*`, "i");
  //             productsFound = productsFound.filter(element => palabra.test(removeAccents(element?.nags)));
  //         }
  //         setProducts(productsFound);
  //     }
  //     setUpdatePagination(true);
  // }

  const deactivateProduct = (sts) => {
    if (sts) {
      const productModel = {
        _id: showModal.element.data._id,
        status: showModal.element.status ? "active" : "inactivated",
        name: showModal.element.data.name,
        brand: showModal.element.data.brand,
        nags: showModal.element.data.nags,
        model: showModal.element.data.model,
        year: showModal.element.data.year,
        price: showModal.element.data.price,
        serviceTime: showModal.element.data.serviceTime,
        barcode2: showModal.element.data.barcode2,
        glassType: showModal.element.data.glassType,
        type_car: showModal.element.data.type_car,
        totalQuantity: showModal.element.data.totalQuantity,
        by: showModal.element.data.by ? { _id: showModal.element.data.by._id } : { _id: dataSession._id },
        vendors:
                    showModal.element.data.vendors.map((vendorElement) => {
                      return (
                        { _id: vendorElement._id }
                      );
                    })
        ,
        features: showModal.element.data.features,
        description: showModal.element.data.description,
        productImages: showModal.element.data.productImages,
        productSpecification: showModal.element.data.productSpecification ?
          { _id: showModal.element.data.productSpecification._id } : {}
      };
      setLoader(true);
      updateProduct(productModel._id, productModel).then(res => {
        if (res.status === 200) {
          // getProducts().then(resGet => {
          //     if (res.status === 200 && resGet.data.length > 0) {
          //         setProducts(orderProductsByNag(resGet.data));
          //         setProductsUniv(orderProductsByNag(resGet.data));
          //     } else {
          //         setProducts([]);
          //         setProductsUniv([]);
          //     }

          //     setLoader(false);
          //     toast.success("Product updated successfully", alertOptions);
          // });
          getData(page, filterPagination);
          toast.success("Product updated successfully", alertOptions);
        } else {
          setLoader(false);
          toast.warning(res.response.data.message, alertOptions);
        }
        setShowModal({...showModal, status: false, element: null});
      }).catch(error => {
        setLoader(false);
        toast.warning(error.response.data.message, alertOptions);
      });
    }
  };

  const orderProductsByNag = (array_products) => {
    if (array_products.length > 0) {
      array_products = array_products.sort((a, b) => {
        return a.nags.localeCompare(b.nags, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
      return array_products;
    }
    return [];
  };

  const changePage = (pge) => {
    new Promise((resolve) => {
      getData(pge, filterPagination);
      resolve();
    }).then(() => {
      setPage(pge);
    });
  };

  useEffect(() => {
    getData(1, filterPagination);
  }, []);

  return (
    <>
      { loader && <Loader /> }

      <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
        <div className="text-center">
          <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">Products</h1>
        </div>
        <div className="pt-3">
          <Filters
            enableFilters={permits['PRODUCTS']['FILTER']}
            enableAdd={permits['PRODUCTS']['ADD']}
            enableHistory={permits['PRODUCTS']['HISTORY']}
            statusOptions={stateOptions}
            onFilters={handleFilters}
          />
        </div>
        <div>
          <TableProduct
            enableEdit={permits['PRODUCTS']['EDIT']}
            enableDeactivate={permits['PRODUCTS']['DEACTIVATE']}
            currntPg={page}
            products={products}
            totalCount={totalCount}
            onPage={(pg) => changePage(pg)}
            onStatus={(productElement, sts) => {
              setShowModal({...showModal, status: true, element: { status: sts, data: productElement }});
            }}
            onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
            updatePagination={updatePagination}
            onResponseUpdatePagination={(resp) => setUpdatePagination(resp)}
          />
        </div>
      </div>

      { showModal.status ?
        <Modal
          title={`¿Are you sure you want to ${ showModal.element.status ? "activate" : "deactivate"} the product?`}
          onClose={() => setShowModal({...showModal, status: false, element: null})}
          onResponse={deactivateProduct}
        />
        :
        null
      }

      {
        showCompatible.status ?
          <ModalCompatible
            products={showCompatible.element}
            onClose={() => setShowCompatible({...showCompatible, status: false, element: null})}
          />
          : null
      }
    </>
  );
};

export default Users;