import { BsInfoCircleFill } from "react-icons/bs";
import FeaturesByProductOrder from "../../../wholesaler/mycart/FeaturesByProductOrder";

const RowsOrganize = ({ item, onOrganize, onCompatible, onFeatures }) => {
    return (
        <tr>
            <td className="w-[40%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                <div className="lg:flex lg:justify-center lg:items-center lg:space-x-1">
                    {
                        item.compatible_vehicles.map((prodComp, i) => {
                            if (i === 0) {
                                return (
                                    <div key={i} >
                                        <p className="truncate w-40 m-auto" key={i} title={
                                            `${prodComp?.nag} ` +
                                            `(${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year})`
                                        }>
                                            { prodComp?.nag }{' ('}
                                            { String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year }{') '}
                                        </p>
                                        <p className="truncate w-40 m-auto" key={i} title={
                                            `${prodComp?.brand} ` +
                                            `${prodComp?.model} ` +
                                            `${prodComp?.type_car}`
                                        }>
                                            { prodComp?.brand }{' '}
                                            { prodComp?.model }{' '}
                                            { prodComp?.type_car }
                                        </p>
                                    </div>
                                )
                            }
                        })
                    }
                    <div className="pt-1 pl-2">
                        <div>
                            {
                                item.compatible_vehicles.length > 1 ?
                                    <p className="truncate w-40 m-auto font-semibold cursor-pointer" onClick={() => onCompatible(item)}>
                                        See compatible vehicles +
                                    </p>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </td>
            <td className="w-[14%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <FeaturesByProductOrder features={item} />
            </td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">{ item.quantity }</td>
            <td className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                <ul className="pb-1">
                    {
                        item?.racks?.map((elem, i) => (
                            <li key={i} className="flex justify-center items-center space-x-3 px-3">
                                <p className="text-left w-full md:w-16 xl:w-24 font-semibold truncate">{ elem?.name }</p>
                                <p className="font-semibold">{ elem?.quantity }</p>
                            </li>
                        ))
                    }
                </ul>
                <button
                    className={`w-full md:w-[90%] xl:w-[80%] text-white text-xs p-2 rounded-xl ${item?.isContinue ? "bg-green-600" : "bg-ag-secondary-dark"}`}
                    type="button"
                    onClick={() => onOrganize(item)}
                >
                    Dispatch
                </button>
            </td>
            <td className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                <input
                    className="w-full h-12 text-center border border-ag-secondary-light rounded-xl disabled:bg-zinc-200"
                    name="note"
                    type="text"
                    disabled={true}
                />
            </td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                <div className="space-y-3">
                    <div>
                        <span>{'---'}</span>
                    </div>
                    {/* {
                        item?.check ? null :
                        <div className="flex justify-center items-center space-x-1 text-zinc-400">
                            <span className="text-lg"><BsFillCheckCircleFill /></span>
                            <span className="text-xs">Check</span>
                        </div>
                    } */}
                    {/* <div className="flex justify-center items-center space-x-1 text-zinc-400">
                        <span className="text-lg"><AiFillCloseSquare /></span>
                        <span className="text-xs">Report Incident</span>
                    </div> */}
                </div>
            </td>
        </tr>
    );
}

export default RowsOrganize;