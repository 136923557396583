import { useEffect, useState } from "react";
import { BsImage } from "react-icons/bs";
import { formatCurrency } from "../../../../../utils/Utilities";

const RowsReceivedProducts = ({ product, onProduct, onOrganize, onlyRead, onCompatible }) => {
    const [readView, setReadView] = useState(false);
    const [valueNote, setValueNote] = useState("");
    const [qtyVal, setQtyVal] = useState({ exp: 0, real: 0 });

    const handleChange = (evt) => {
        setValueNote(evt.target.value);
        onProduct(evt, product);
    }

    useEffect(() => {
        if (product?.notes) setValueNote(product?.notes);
    }, [product?.notes]);

    useEffect(() => {
        setReadView(onlyRead);
    }, [onlyRead]);
/*se añadio una verificación nullish "product &&"*/
    useEffect(() => {
        if (onlyRead && product && product?.incomingRack && product?.incomingRack.length > 0) {
            let newReal = 0;
            for (let i = 0; i < product?.incomingRack.length; i++) {
                newReal += Number(product?.incomingRack[i].quantity);
            }
            setQtyVal({...qtyVal, exp: newReal + Number(product?.discrepancy), real: newReal});
        }
    }, [product]);
    
    
    return (
        <tr>
            <td className="w-[40%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">
                <div className="w-full flex justify-center items-center space-x-2">
                    <div className="w-[20%] flex justify-center items-center">
                        { product.productInventory.product?.productImages[0] ?
                            <div className="w-[45px] h-[45px] flex justify-center items-center border border-ag-secondary-light rounded-lg p-1">
                                <img className="w-full h-full object-contain" src={product.productInventory.product.productImages[0]} />
                            </div>
                        :
                            <span className="text-4xl"><BsImage /></span>
                        }
                    </div>
                    <div className="w-[80%] text-left text-[10px]">
                        <div className="space-y-[-3px]">
                            <div>
                                <span>{ product.data.productInventory.product.nags }</span>
                                <span>{' - '}</span>
                                <span>{ product.data.productInventory.product.name }</span>
                            </div>
                            <div>
                                {
                                    product.data.productInventory.product.compatible_vehicles.map((prodComp, i) => {
                                        if (i <= 2) {
                                            return (
                                                <p className="truncate w-32 md:w-44 xl:w-60" key={i} title={
                                                    `${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year} ` +
                                                    `${prodComp?.brand} ` +
                                                    `${prodComp?.model} ` +
                                                    `${prodComp?.type_car}`
                                                }>
                                                    { String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year }{' '}
                                                    { prodComp?.brand }{' '}
                                                    { prodComp?.model }{' '}
                                                    { prodComp?.type_car }
                                                </p>
                                            )
                                        }
                                    })
                                }
                                {
                                    product.data.productInventory.product.compatible_vehicles.length > 3 ?
                                        <p className="truncate w-32 md:w-44 xl:w-60 font-semibold cursor-pointer" onClick={() => onCompatible(product.data.productInventory.product)}>
                                            See compatible vehicles +
                                        </p>
                                    : null
                                }
                            </div>
                        </div>
                        <div className="space-y-[-3px]">
                            <div>
                                <span className="font-bold">Qty in stock at Warehouse: { product?.data?.quantity }</span>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">
                { readView ?
                    product?.incomingRack ? product?.incomingRack.map((eleRack, i) => {
                        return (
                            <div key={i} className="flex justify-center items-center space-x-3">
                                <span className="font-bold">{eleRack.rack}</span>
                                <span>{eleRack.quantity}</span>
                            </div>
                        )
                    }) : "---"
                :
                    product?.racks && product?.racks.length > 0 ?
                        <div className="space-y-2">
                            <ul>
                                {
                                    product?.racks.map((rckElem, idx) => (
                                        <li className="grid gap-2 grid-cols-2" key={idx}>
                                            <p className="w-16 text-xs font-semibold truncate" title={rckElem.name}>{ rckElem.name }</p>
                                            <p className="text-xs font-semibold">{ rckElem.quantity }</p>
                                        </li>
                                    ))
                                }
                            </ul>
                            <button
                                className="bg-ag-secondary-dark text-white text-xs px-6 py-1 rounded-xl"
                                type="button"
                                onClick={() => onOrganize(product)}
                            >
                                Organize
                            </button>
                        </div>
                    :
                        <button
                            className="bg-ag-secondary-dark text-white text-xs px-6 py-1 rounded-xl"
                            type="button"
                            onClick={() => onOrganize(product)}
                        >
                            Organize
                        </button>
                }
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">
                { product?.quantity ? product?.quantity : onlyRead ? qtyVal.exp : "---" }
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">
                { product?.realQuantity ? product?.realQuantity : onlyRead ? qtyVal.real : "---" }
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">
                { product.discrepancy ? product.discrepancy : 0 }
            </td>
            <td className="w-[12%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">
                { readView ? product.note ? product.note : "---" :
                    <input
                        className="w-full h-10 text-center border border-ag-secondary-light rounded-md"
                        name="notes"
                        type="text"
                        onChange={handleChange}
                        value={valueNote}
                    />
                }
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">{ formatCurrency(product.defaultCost) }</td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">
                <div className="w-full flex justify-between items-center">
                    <span>{ formatCurrency(product.totalCost) }</span>
                    {/* { readView ? null :
                        <button type="button" onClick={() => onDelete(product)}>
                            <span className="text-red-600 text-4xl">
                                <AiOutlineDelete />
                            </span>
                        </button>
                    } */}
                </div>
            </td>
        </tr>
    );
}

export default RowsReceivedProducts;