import { useEffect, useState } from "react";
import RowsInventory from "./RowsInventory";
import Pagination from "../../../components/pagination/Pagination";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../components/loader/Loader";

const TableInventory = ({ enableEdit, enableRecord, enableOrderQuery, arrInventory, currntPg, pagesData, onPage, onHistory, onStatus, onImage, onPrice, onOrder, onCompatible, finalMessage, showFilters }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(5);

    useEffect(() => {
        setCurrentPage(currntPg);
    }, [currntPg]);

    const divStyleWithFilters = {
        height: `calc(100vh - 410px)`,
    };

    const divStyleWithOutFilters = {
        height: `calc(100vh - 299px)`,
    };

    return (
        <div>
            {/* <div className="overflow-auto min-h-[38vh] lg:min-h-[47vh] 3xl:min-h-[55vh]"> */}
            {/* <div className="overflow-auto">
                <table className="w-full text-center text-ag-secondary">
                    <thead>
                        <tr className="bg-ag-secondary-table">
                            <th className="w-[25%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-32 md:px-40 xl:px-2 py-4">Products</th>
                            <th className="w-[9%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">Compatible vehicles</th>
                            <th className="w-[14%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">Description</th>
                            <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">Features</th>
                            <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">Warehouse</th>
                            <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">Stock</th>
                            <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">Sales price</th>
                            <th className="w-[11%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">Actions</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            arrInventory.map((element, i) => {
                                return (
                                    <RowsInventory
                                        key={i}
                                        enableEdit={enableEdit}
                                        enableRecord={enableRecord}
                                        enableOrderQuery={enableOrderQuery}
                                        inventory={element}
                                        onHistory={onHistory}
                                        onStatus={onStatus}
                                        onImage={onImage}
                                        onPrice={onPrice}
                                        onOrder={onOrder}
                                        onCompatible={onCompatible}
                                    />
                                )
                            })
                        }
                    </tbody>
                </table>
            </div> */}
            {/* <div style={{ height: '250px' }}> */}
            <div style={ showFilters ? divStyleWithFilters : divStyleWithOutFilters }>
                <div id="scrollableDiv"
                    style={{
                        // display: "flex",
                        // flexDirection: "column",
                        height: "100%",
                        overflow: "auto",
                    }}>
                    <InfiniteScroll
                        dataLength={arrInventory? arrInventory.length: 0}
                        next={onPage}
                        hasMore={finalMessage}
                        // loader={arrInventory.length > 0 ? <p className="text-center text-[20px] text-[#00A829] font-bold">Loading...</p> : <></>}
                        loader={arrInventory.length > 0 
                            ? 
                        <div className="h-[100px] text-center flex items-center justify-center space-x-5">
                            <span className="loaderTwo"></span>
                            <span className="text-[#00000099] text-base md:text-xl">Loading, please wait...</span>
                        </div> 
                            : 
                        <></>}
                        scrollableTarget="scrollableDiv"
                        endMessage={<p className="text-center text-ag-secondary-letter text-[20px]">No more items to display.</p>}
                    >
                        <table className="w-full text-center text-ag-secondary">
                            <thead>
                                <tr className="bg-ag-secondary-table">
                                    <th className="w-[25%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-32 md:px-40 xl:px-2 py-4">Products</th>
                                    <th className="w-[9%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">Compatible vehicles</th>
                                    <th className="w-[14%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">Description</th>
                                    <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">Features</th>
                                    <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">Warehouse</th>
                                    <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">Stock</th>
                                    <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">Sales price</th>
                                    <th className="w-[11%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    arrInventory.length === 0 ?
                                        <></>
                                    :
                                    arrInventory.map((element, i) => {
                                        return (
                                            <RowsInventory
                                                key={i}
                                                enableEdit={enableEdit}
                                                enableRecord={enableRecord}
                                                enableOrderQuery={enableOrderQuery}
                                                inventory={element}
                                                onHistory={onHistory}
                                                onStatus={onStatus}
                                                onImage={onImage}
                                                onPrice={onPrice}
                                                onOrder={onOrder}
                                                onCompatible={onCompatible}
                                            />
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        {/* {items.map((i, index) => (
                            <div style={style} key={index}>
                            div - #{index}
                            </div>
                        ))} */}
                    </InfiniteScroll>
                </div>
            </div>

            {/* <div className="px-5 pt-5 pb-2 m-auto">
                <Pagination
                    currentPage={currentPage}
                    totalCount={pagesData.records}
                    pageSize={recordsPerPage}
                    onPageChange={page => onPage(page)}
                />
            </div> */}
        </div>
    );
}

export default TableInventory;