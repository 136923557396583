import { formatCurrency } from "../../../utils/Utilities";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdImage } from "react-icons/io";
import { BsInfoCircleFill } from "react-icons/bs";

const MyCartElement = ({ product, onQuantity, onRemoveProduct, onUpdate, onCompatible, onFeatures }) => {
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);

  const handleChange = (evt) => {
    const pattern = new RegExp(/^[0-9\s]+$/g);
    if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
      setQuantity(evt.target.value);
      onQuantity(product?.productInventory._id, evt.target.value);
    }
  };

  useEffect(() => {
    if (product !== null) setQuantity(product?.quantity);
  }, [product]);

  return (
    <div className="p-2 md:flex md:justify-between md:items-center md:space-x-3 space-y-3 md:space-y-0 border border-ag-secondary rounded-xl">
      <div className="flex justify-end">
        <button className="block md:hidden text-xl p-1 text-ag-secondary-letter cursor-pointer" type="button" onClick={() => onRemoveProduct(product?._id, product?.quantity)}>
          X
        </button>
      </div>

      <div className={`flex justify-center items-center ${product?.productInventory?.product?.productImages.length > 0 ? "bg-black" : ""}`}>
        {
          product && product?.productInventory?.product?.productImages.length > 0 ?
            <div className="w-[110px] h-[110px] flex justify-center items-center m-auto">
              <img className="w-full h-full object-contain" src={product?.productInventory?.product?.productImages[0]} />
            </div>
            :
            <span className="px-4 text-[80px] lg:text-[50px] xl:text-[80px] text-ag-secondary-letter">
              <IoMdImage />
            </span>
        }
      </div>

      <div className="w-full space-y-2">
        <div className="text-center md:text-left cursor-pointer">
          <p className="text-base lg:text-sm xl:text-base" onClick={() => navigate(`/wholesaler/search/${product?.productInventory?._id}`, { state: { qty: quantity }})}>
            { product?.productInventory?.product?.nags }
          </p>

          <p className="text-base lg:text-sm xl:text-base">
            {
              product.compatible_vehicles.map((prodComp, i) => {
                if (i < 1) {
                  return (
                    <p
                      className="w-full md:w-60 lg:w-64 xl:w-80 truncate"
                      key={i}
                      title={
                        `${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `(${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]})` : prodComp?.year} ` +
                        `${prodComp?.brand} ` +
                        `${prodComp?.model} ` +
                        `${prodComp?.type_car}`
                      }
                      onClick={() => navigate(`/wholesaler/search/${product?.productInventory?._id}`, { state: { qty: quantity }})}>
                      { String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `(${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]})` : prodComp?.year }{' '}
                      { prodComp?.brand }{' '}
                      { prodComp?.model }{' '}
                      { prodComp?.type_car }
                    </p>
                  );
                }
              })
            }
            {
              product.compatible_vehicles.length > 1 ?
                <p className="truncate font-semibold cursor-pointer" onClick={() => onCompatible(product)}>
                  See compatible vehicles +
                </p>
                : null
            }
          </p>
          <div className="flex justify-start items-center space-x-2" onClick={() => onFeatures({ ...product.productInventory.product, compatible_vehicles: product.compatible_vehicles })}>
            <p className="text-zinc-600 text-base lg:text-sm xl:text-base font-semibold">See features</p>

            <p className="text-zinc-600"><BsInfoCircleFill /></p>
          </div>
        </div>

        <div className="text-center md:text-left" onClick={() => navigate(`/wholesaler/search/${product?.productInventory?._id}`, { state: { qty: quantity }})}>
          <p className="text-ag-secondary-letter text-sm">{ product?.productInventory?.product?.glassType }</p>
        </div>

        <div className="flex justify-center md:justify-start items-center space-x-3 pt-1">
          <span className="text-ag-secondary-letter text-xs">Quantity</span>

          <input
            className="w-24 px-3 py-0 text-center text-ag-secondary-letter border border-ag-secondary-letter"
            type="text"
            onChange={handleChange}
            onBlur={() => onUpdate(product, quantity)}
            value={quantity}
            maxLength={8}
          />
        </div>
      </div>

      <div className="w-full text-center md:text-left">
        <p className="font-semibold">
          { formatCurrency(Number(quantity) * Number(product?.price)) }
        </p>
      </div>

      <div>
        <button className="hidden md:block mb-20 text-ag-secondary-letter cursor-pointer" type="button" onClick={() => onRemoveProduct(product?._id)}>
          X
        </button>
      </div>
    </div>
  );
};

export default MyCartElement;