import { useContext, useEffect, useRef, useState } from "react";
import { getWarehouses } from "../../../services/Warehouse";
import { getUsers } from "../../../services/Administrator";
import { getReportsByOrders, getReportsByReturns } from "../../../services/Reports";
import { toast } from "react-toastify";
import { alertOptions, formatDateEngSlash, formatReverseDate, removeAccents } from "../../../utils/Utilities";
import { CSVLink } from "react-csv";
import { headers, getRowsCSV } from "../../../utils/ExcelData";
import { formatDateWordEng } from "../../../utils/Utilities";
import { PermitsContext } from "../../../services/Permits";
import Loader from "../../../components/loader/Loader";
import Filters from "./Filters";
import TableReports from "./TableReports";

const Reports = () => {
  const { permits, dataSession } = useContext(PermitsContext);

  const csvLinkEl = useRef();
  const [nameCSV, setNameCSV] = useState("");
  const [rows, setRows] = useState([]);

  const [loader, setLoader] = useState(false);
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [employeesOptions, setEmployeesOptions] = useState([]);
  const [reports, setReports] = useState([]);
  const [reportsUniv, setReportsUniv] = useState([]);

  const handleFilters = (filtPrms, type, typeData) => {
    if (type === "filters") {
      let palabra = new RegExp(`${removeAccents(filtPrms)}.*`, "i");
      const reportsFound = reportsUniv.filter(element => {
        // Busqueda en productos
        let productsFound = [];
        if (element?.productsOrder) {
          productsFound = element?.productsOrder.filter(elementAux => palabra.test(elementAux?.productInventory?.product?.name));
        }

        // Busqueda general
        if (
          palabra.test(removeAccents(`ON-${String(element?._id).substring(String(element?._id).length - 5).toLocaleUpperCase()}`)) ||
                    palabra.test(removeAccents(element?.employee?.role?.name)) ||
                    palabra.test(removeAccents(`SN-${String(element?.sale?._id).substring(String(element?.sale?._id).length - 5).toLocaleUpperCase()}`)) ||
                    palabra.test(removeAccents(element.warehouse?.name)) ||
                    palabra.test(removeAccents(element?.wholesaler?.names)) ||
                    palabra.test(removeAccents(element?.wholesalerUser?.names)) ||
                    palabra.test(`${removeAccents(element?.retailUser?.names)} ${removeAccents(element?.retailUser?.lastNames)}`) ||
                    palabra.test(element.total) ||
                    palabra.test(element.quantity) ||
                    palabra.test(formatDateEngSlash(element?.createdAt)) ||
                    palabra.test(element?.productOrder?.productInventory?.product?.name) ||
                    productsFound.length > 0
        ) {
          return element;
        }
      });
      setReports(reportsFound);
    } else {
      let filtObj = {};
      if (filtPrms.warehouse === "" && filtPrms.startDate === "" && filtPrms.endDate === "" && filtPrms.employee === "") {
        if (typeData === "order") {
          getDataOrders({});
        } else {
          getDataReturns({});
        }
      } else {
        if ((filtPrms.startDate !== "" && filtPrms.endDate === "") || (filtPrms.startDate === "" && filtPrms.endDate !== "")) {
          toast.info("Please enter both dates to perform the filter", alertOptions);
        } else if (filtPrms.startDate !== "" && filtPrms.endDate !== "") {
          if (formatReverseDate(filtPrms.startDate) > formatReverseDate(filtPrms.endDate)) {
            toast.info("The start date is greater than the end date", alertOptions);
          } else {
            for (const key in filtPrms) {
              if (Object.hasOwnProperty.call(filtPrms, key) && filtPrms[key] !== "") filtObj[key] = filtPrms[key];
            }

            if (typeData === "order") {
              getDataOrders(filtObj);
            } else {
              getDataReturns(filtObj);
            }
          }
        } else {
          for (const key in filtPrms) {
            if (Object.hasOwnProperty.call(filtPrms, key) && filtPrms[key] !== "") filtObj[key] = filtPrms[key];
          }

          if (typeData === "order") {
            getDataOrders(filtObj);
          } else {
            getDataReturns(filtObj);
          }
        }
      }
    }
  };

  const getOptions = () => {
    getWarehouses().then(res => {
      if (res.status === 200 && res.data.length > 0) {
        if (dataSession.userType === "ADMIN") {
          const warehousesCurrent = res.data.filter(element => !element.deleted);
          setWarehouseOptions(warehousesCurrent);
        } else {
          const warehousesCurrent = res.data.filter(element => {
            const foundWH = dataSession.allWarehouse.find(elem => element._id === elem._id);
            if (!element.deleted && foundWH) return element;
          });

          setWarehouseOptions(warehousesCurrent);
        }
      }
    });

    getUsers().then(res => {
      if (res.status === 200 && res.data.length > 0) {
        if (dataSession.userType === "ADMIN") {
          const employeesCurrent = res.data.filter(element => !element.deleted);
          setEmployeesOptions(employeesCurrent);
        } else {
          const employeesCurrent = res.data.filter(element => {
            const foundWH = dataSession.allWarehouse.find(elem => element.warehouse._id === elem._id);
            if (!element.deleted && foundWH) return element;
          });
          setEmployeesOptions(employeesCurrent);
        }
      }
    });
  };

  const getDataReturns = (paramsObject) => {
    if (paramsObject.startDate && paramsObject.startDate !== "") {
      paramsObject.startDate = formatReverseDate(paramsObject.startDate);
      paramsObject.endDate = formatReverseDate(paramsObject.endDate);
    }
    setLoader(true);
    getReportsByReturns(paramsObject).then(res => {
      if (res.status === 200 && res.data.length > 0) {
        const newReturns = res.data.map(element => ({...element, type: "return"})).filter(element => {
          if (dataSession.userType === "ADMIN") {
            return element;
          }
          const foundWarehouse = dataSession.allWarehouse.find(elemWH => elemWH._id === element.warehouse._id);
          if (foundWarehouse) return element;

        });

        const returnsSort = newReturns.sort(function(a, b){
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setReports(returnsSort);
        setReportsUniv(returnsSort);
      } else {
        toast.warning("Search without records", alertOptions);
      }
      setLoader(false);
    });
  };

  const getDataOrders = (paramsObject) => {
    if (paramsObject.startDate && paramsObject.startDate !== "") {
      paramsObject.startDate = formatReverseDate(paramsObject.startDate);
      paramsObject.endDate = formatReverseDate(paramsObject.endDate);
    }
    setLoader(true);
    getReportsByOrders(paramsObject).then(res => {
      if (res.status === 200 && res.data.length > 0) {
        const newOrders = res.data.map(element => ({...element, type: "order"})).filter(element => {
          if (dataSession.userType === "ADMIN") {
            return element;
          }
          const foundWarehouse = dataSession.allWarehouse.find(elemWH => elemWH._id === element.warehouse._id);
          if (foundWarehouse) return element;

        });

        const ordersSort = newOrders.sort(function(a, b){
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setReports(ordersSort);
        setReportsUniv(ordersSort);
      } else {
        toast.warning("Search without records", alertOptions);
      }
      setLoader(false);
    });
  };

  const exportCSV = (typeData) => {
    new Promise((resolve, reject) => {
      const dataRows = getRowsCSV(reports, typeData);
      setRows(dataRows);
      setNameCSV(`Report_${typeData} ${formatDateWordEng(new Date())}`);
      resolve();
    }).then(() => {
      csvLinkEl.current.link.click();
    });
  };

  useEffect(() => {
    getOptions();
    getDataOrders({});
  }, [dataSession]);

  return (
    <>
      <CSVLink headers={headers} filename={nameCSV} data={rows} ref={csvLinkEl} />

      { loader && <Loader /> }

      <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
        <div className="text-center">
          <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">Sales Report</h1>
        </div>
        <div className="pt-3">
          <Filters
            enableFilters={permits['REPORTS']['FILTER']}
            enableDownload={permits['REPORTS']['DOWNLOAD']}
            employeeOptions={employeesOptions}
            warehouseOptions={warehouseOptions}
            onFilters={handleFilters}
            onParams={(prmsObj) => getDataOrders(prmsObj)}
            onReturns={(prmsObj) => getDataReturns(prmsObj)}
            onDownload={exportCSV}
          />
        </div>
        <div className="pt-3">
          <TableReports reports={reports} />
        </div>
      </div>
    </>
  );
};

export default Reports;